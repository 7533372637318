import { template as template_10920ada3bd14ebf9bf807f30296bcfa } from "@ember/template-compiler";
const FKLabel = template_10920ada3bd14ebf9bf807f30296bcfa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
