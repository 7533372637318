import { template as template_a4a190fb36d5446c9b576a6efd5d245c } from "@ember/template-compiler";
const FKControlMenuContainer = template_a4a190fb36d5446c9b576a6efd5d245c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
