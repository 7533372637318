import { template as template_8a6a4a130e7a4a238ffd26f3a7e72f89 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8a6a4a130e7a4a238ffd26f3a7e72f89(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
