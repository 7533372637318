import { template as template_c1f962f2b392402d8470d42855a59efb } from "@ember/template-compiler";
const WelcomeHeader = template_c1f962f2b392402d8470d42855a59efb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
